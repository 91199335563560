import { BrowserRouter, Route, Link } from "react-router-dom";

function Error() {
  return (
    
    <h1>Error
    </h1>

    
  );
}

export default Error;