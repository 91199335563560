import 'bootstrap/dist/css/bootstrap.min.css';
import LftMap from '.././components/map.js'
import "leaflet/dist/leaflet.css";
import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Analytics } from '@vercel/analytics/react';


// import './App.css';




function Home() {
  const [show, setShow] = useState(false)
  
  return (
    
    
    <div>
            {/* <Popup trigger=
                {useEffect(()=>{
                  setTimeout(()=>{
                    setShow(true)
                  }, 2000)
                }, [])}
                modal nested>
                {
                    close => (
                        <div className='modal'>
                            <div className='content'>
                                Welcome to GFG!!!
                            </div>
                            <div>
                                
                            </div>
                        </div>
                    )
                }
            </Popup> */}
            
      <LftMap/>
      <Analytics />

    </div>
  );
}


export default Home;
