import { BrowserRouter, Route, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import '.././components/navbar.css';

function About() {
  return (
    <>
    <Container>
      <div className = "justify-content-md-center mt-5 logo_n2"> 
          <div className = "mt-2">
            <h1>About</h1>
            <h6> STORMVIEW brings together a number of high-quality weather products to help you look beyond the horizon.</h6>
          </div>  
          <div className = "mt-4">
            <h3>Sources</h3>
            <h6>STORMVIEW aims to provided to as close to realtime data as possible. Data is sourced from live data feeds and fed into processing alogirthms. The site renders some of the outputs of the algorithms.</h6>
           </div>
          <div className = "mt-4">
            <h3>Contact</h3>
            <h6>Would you like to share any feedback or get in touch? Send an email to stormviewconnect@gmail.com </h6>
          </div>
          

      </div>
    </Container>
   
    </>

    
  );
}

export default About;