import { useState, useEffect, useRef } from 'react'
import "leaflet/dist/leaflet.css";
import './map.css'
import "./dist/leaflet.control.select.css"
import "./dist/leaflet.control.select.js"
import "./dist/leaflet.control.select.src.js"
import 'leaflet-geometryutil'
import 'leaflet-easybutton'
import warnings from './warning.png';
import USCONT from './cmaped.png'
import sqimg from './sq.png';
import wt from './wtest.gif'
import L from 'leaflet';



const NAMES = ["KABR", "KENX", "KABX", "KAMA", "KGUA", "KFFC", "KBBX", "KABC", "KBLX", "KBGM", "KACG", "KBMX", "KBIS", "KFCX", "KCBX", "KBOX", "KBRO", "KBUF", "KCXX", "KKSG", "KFDX", "KCBW", "KICX", "KGRK", "KRLX", "KCLX", "KCYS", "KLOT", "KCLE", "KCAE", "KGWX", "KCRP", "KFWS", "KFTG", "KDMX", "KDTX", "KDDC", "KDOX", "KDLH", "KDYX", "KEYX", "KEVX", "KLRX", "KEPZ", "KBHX", "KVWX", "KFSX", "KHPX", "KPOE", "KEOX", "KSRX", "KIWX", "KFDR", "KAPX", "KGGW", "KGLD", "KMVX", "KGJX", "KGRR", "KTFX", "KGRB", "KGSP", "KUEX", "KHDX", "KHGX", "KHTX", "KIND", "KJKL", "KDGX", "KJAX", "KODN", "KEAX", "KHKI", "KAHG", "KBYX", "KAKC", "KMRX", "KHKM", "KKJK", "KARX", "KLCH", "KLGX", "KESX", "KDFX", "KILX", "KLZK", "KVTX", "KLVX", "KLBB", "KMQT", "KMXX", "KMAX", "KMLB", "KNQA", "KAMX", "KAIH", "KMAF", "KMKX", "KMPX", "KMBX", "KMSX", "KMOB", "KHMO", "KTYX", "KVAX", "KMHX", "KOHX", "KLIX", "KAEC", "KLNX", "KTLX", "KOAX", "KPAH", "KAPD", "KPDT", "KDIX", "KIWA", "KPBZ", "KSFX", "KGYX", "KRTX", "KPUX", "KJUA", "KDVN", "KRAX", "KUDX", "KRGX", "KRIW", "KJGX", "KDAX", "KMTX", "KSJT", "KEWX", "KNKX", "KMUX", "KHNX", "KSOX", "KATX", "KSHV", "KFSD", "KHWA", "KOTX", "KSGF", "KCCX", "KLWX", "KLSX", "KTLH", "KTBW", "KTWX", "KEMX", "KINX", "KOKX", "KVNX", "KVBX", "KAKQ", "KICT", "KLTX", "KILN", "KYUX"];

const LAT = [45.456  , 42.586  , 35.15   , 35.233  , 13.45   , 33.363  ,
  39.496  , 60.78   , 45.854  , 42.2    , 56.85   , 33.172  ,
  46.771  , 37.024  , 43.49   , 41.956  , 25.916  , 42.949  ,
  44.511  , 37.207  , 34.634  , 46.039  , 37.591  , 30.722  ,
  38.311  , 32.655  , 41.152  , 41.604  , 41.413  , 33.949  ,
  33.897  , 27.784  , 32.573  , 39.78665, 41.731  , 42.7    ,
  37.761  , 38.826  , 46.837  , 32.538  , 35.098  , 30.565  ,
  40.74   , 31.87313, 40.499  , 38.26   , 34.574  , 36.737  ,
  31.155  , 31.46   , 35.29   , 41.359  , 34.362  , 44.906  ,
  48.206  , 39.367  , 47.528  , 39.062  , 42.894  , 47.46   ,
  44.499  , 34.883  , 40.321  , 33.077  , 29.472  , 34.931  ,
  39.708  , 37.591  , 32.28   , 30.485  , 26.3556 , 38.81   ,
  21.88   , 60.72   , 24.597  , 58.67   , 36.168  , 20.12   ,
  35.924  , 43.823  , 30.125  , 47.116  , 35.701  , 29.273  ,
  40.15   , 34.836  , 34.412  , 37.975  , 33.654  , 46.531  ,
  32.537  , 42.081  , 28.113  , 35.345  , 25.611  , 59.45   ,
  31.943  , 42.968  , 44.849  , 48.393  , 47.041  , 30.679  ,
  21.12   , 43.756  , 30.89   , 34.776  , 36.247  , 30.337  ,
  64.5    , 41.958  , 35.333  , 41.32   , 37.068  , 65.03   ,
  45.691  , 39.947  , 33.289  , 40.532  , 43.106  , 43.891  ,
  45.715  , 38.46   , 18.1    , 41.612  , 35.665  , 44.125  ,
  39.754  , 43.066  , 32.675  , 38.501  , 41.263  , 31.371  ,
  29.704  , 32.91937, 37.155  , 36.314  , 33.818  , 48.195  ,
  32.451  , 43.588  , 19.08   , 47.681  , 37.235  , 40.923  ,
  38.976  , 38.699  , 30.398  , 27.705  , 38.997  , 31.894  ,
  36.175  , 40.865  , 36.741  , 34.839  , 36.984  , 37.654  ,
  33.989  , 39.42   , 32.495  ];

const LONG = [ -98.413  ,  -74.064  , -106.824  , -101.709  ,  144.8    ,
    -84.566  , -121.632  , -161.87   , -108.607  ,  -75.985  ,
   -135.52   ,  -86.77   , -100.76   ,  -80.274  , -116.236  ,
    -71.137  ,  -97.419  ,  -78.737  ,  -73.166  ,  127.285  ,
   -103.619  ,  -67.806  , -112.862  ,  -97.383  ,  -81.723  ,
    -81.042  , -104.806  ,  -88.085  ,  -81.86   ,  -81.119  ,
    -88.329  ,  -97.511  ,  -97.303  , -104.54586,  -93.723  ,
    -83.472  ,  -99.969  ,  -75.44   ,  -92.21   ,  -99.254  ,
   -117.561  ,  -85.922  , -116.803  , -106.69802, -124.292  ,
    -87.724  , -111.198  ,  -87.285  ,  -92.976  ,  -85.459  ,
    -94.362  ,  -85.7    ,  -98.977  ,  -84.72   , -106.625  ,
   -101.7    ,  -97.325  , -108.214  ,  -85.545  , -111.385  ,
    -88.111  ,  -82.22   ,  -98.442  , -106.12   ,  -95.079  ,
    -86.084  ,  -86.28   ,  -83.313  ,  -89.984  ,  -81.702  ,
    127.7678 ,  -94.264  , -159.55   , -151.35   ,  -81.703  ,
   -156.62   ,  -83.402  , -155.77   ,  126.622  ,  -91.191  ,
    -93.216  , -124.107  , -114.891  , -100.28   ,  -89.337  ,
    -92.262  , -119.179  ,  -85.944  , -101.814  ,  -87.548  ,
    -85.79   , -122.717  ,  -80.654  ,  -89.873  ,  -80.413  ,
   -146.3    , -102.189  ,  -88.551  ,  -93.565  , -100.864  ,
   -113.986  ,  -88.24   , -157.17   ,  -75.68   ,  -83.002  ,
    -76.876  ,  -86.563  ,  -89.825  , -165.28   , -100.576  ,
    -97.278  ,  -96.367  ,  -88.772  , -147.5    , -118.853  ,
    -74.411  , -111.67   ,  -80.218  , -112.686  ,  -70.256  ,
   -122.965  , -104.181  ,  -66.07   ,  -90.581  ,  -78.49   ,
   -102.83   , -119.462  , -108.477  ,  -83.351  , -121.678  ,
   -112.448  , -100.492  ,  -98.029  , -117.04182, -121.898  ,
   -119.632  , -117.636  , -122.496  ,  -93.841  ,  -96.729  ,
   -155.57   , -117.626  ,  -93.4    ,  -78.004  ,  -77.487  ,
    -90.683  ,  -84.329  ,  -82.402  ,  -96.232  , -110.63   ,
    -95.564  ,  -72.864  ,  -98.128  , -120.398  ,  -77.008  ,
    -97.443  ,  -78.429  ,  -83.822  , -114.656  ];
const mpLCR = [];
const mpMRKS = [];
const NRDRS = 159;

const LCRBASEURLA = 'https://opengeo.ncep.noaa.gov:443/geoserver/'
const LCRBASEURLZ = '/ows?SERVICE=WMS&'
var customLCRURL = ""



function LftMap() {
  const mapRef = useRef(null);
  useEffect(()=> { 
    const container = L.DomUtil.get("map");
    if(!mapRef.current ){return;}
    
    const map = L.map("map",{scrollWheelZoom: true}).setView([39.00,-97.00], 5);

    const layer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: "Data by \u0026copy; \u003ca target=\"_blank\" href=\"http://openstreetmap.org\"\u003eOpenStreetMap\u003c/a\u003e, under \u003ca target=\"_blank\" href=\"http://www.openstreetmap.org/copyright\"\u003eODbL\u003c/a\u003e.", "detectRetina": false, "maxNativeZoom": 18, "maxZoom": 18, "minZoom": 0, "noWrap": false, "opacity": 1, "subdomains": "abc", "tms": false, "draggable": true}
      ).addTo(map);
    
    var time = new Date;
    var time_inter = time.setDate(time.getDate() + 1)
    var ISOtime = time.toISOString();
    var maxTtime = ISOtime.slice(0,-13) + "00:00"
    var minTtime = ISOtime.slice(0,-13) + "12:00"
    console.log(maxTtime, "\n", minTtime);


    //Add CONUS Radar
    const USCONTURL = "https://opengeo.ncep.noaa.gov:443/geoserver/conus/conus_bref_qcd/ows?SERVICE=WMS&"
    var wms_options = {layers :"conus_bref_qcd", format: "image/png", transparent: true, opacity: 0.7}
    const usCONTRADAR =  L.tileLayer.wms(USCONTURL,wms_options).addTo(map);

    
    //keep track of tmp
  var curtmpA = false;
  var futtmpA = false;
  var psttmpA = false;
  var pointsA = false;

  //keep track of radar
  var basevelA = false;
  var baserflA = false;
  var radar_mode = "_sr_bref"
  var tempt_mode = "ndfd.conus.t"


  // warnings 
  var wrnA = false;

    //Add MAXT Radar

    // const MAXT = "http://digital.weather.gov/wms.php"
    // var wms_maxt_options = {layers :"ndfd.conus.maxt",format:'image/png', transparent:true,  version: "1.3.0",vt: maxTtime}   // dummy date}
    // const usMAXT =  L.tileLayer.wms(MAXT,wms_maxt_options).addTo(map);

    //  //Add MAXT Radar

     const MAXTp = "http://digital.weather.gov/wms.php"
     var wms_maxtp_options = {layers :"ndfd.conus.maxt.points",format:'image/png', transparent:true,  version: "1.3.0",vt: maxTtime}   // dummy date}
     const usMAXTp =  L.tileLayer.wms(MAXTp,wms_maxtp_options);
 

    ////Add MINT
    // const MINT = "http://digital.weather.gov/wms.php"
    // var wms_mint_options = {layers :"ndfd.conus.mint",format:'image/png', transparent:true,  version: "1.3.0",vt: minTtime,  opacity: 0.7}   // dummy date}
    // const usMINT=  L.tileLayer.wms(MINTp,wms_mint_options).addTo(map);

      //Add MINTp
    const MINTp = "http://digital.weather.gov/wms.php"
    var wms_mintp_options = {layers :"ndfd.conus.mint.points",format:'image/png', transparent:true,  version: "1.3.0",vt: minTtime}   // dummy date}
    const usMINTp=  L.tileLayer.wms(MINTp,wms_mintp_options);

    //Add CURT
    const CURT  = "http://digital.weather.gov/wms.php"
    var wms_curt_options = {layers :tempt_mode,format:'image/png', transparent:true,  version: "1.3.0",vt: maxTtime,  opacity: 1.0}   // dummy date}
    const usCURT=  L.tileLayer.wms(CURT,wms_curt_options);

    //Add CURTp
    const CURTp = "http://digital.weather.gov/wms.php"
    var wms_curtp_options = {layers :"ndfd.conus.t.points",format:'image/png', transparent:true,  version: "1.3.0",vt: maxTtime}   // dummy date}
    const usCURTp =  L.tileLayer.wms(CURTp,wms_curtp_options);

    // //Add States
    const States = "http://digital.weather.gov/wms.php"
    var wms_States = {layers :"states",format:'image/png', transparent:true,  version: "1.3.0",vt: maxTtime}   // dummy date}
    const usStates =  L.tileLayer.wms(States,wms_States);

    //Add Warnings
    const USWRNINGS = "https://opengeo.ncep.noaa.gov:443/geoserver/wwa/warnings/ows?"
    var wms_wrn_options = {LAYERS: "warnings", format: "image/png",transparent:true,style:"fill: #0099cc;"}
    const usCONTWRNINGS=  L.tileLayer.wms(USWRNINGS,wms_wrn_options);


    var sqb = L.icon({
      iconUrl: sqimg,
      iconSize:     [11, 11], // size of the icon
      shadowSize:   [0, 0] // size of the shadow
    });
  
  L.Marker.Custom = L.Marker.extend({ options: { ids: 0} });
  const MRKSGRP = L.layerGroup();
  //setup the markers
  for(var i= 0; i < 159; i++){
      var radar = NAMES[i];
      var LATINT = LAT[i];
      var LONGINT = LONG[i];
      mpMRKS[radar]  = new L.Marker.Custom([LATINT , LONGINT ], {icon: sqb, ids: i});
      
      // console.log(mpMRKS[radar].options.ids)
      //handle clicks
      MRKSGRP.addLayer(mpMRKS[radar]);
      map.addLayer(MRKSGRP);
      mpMRKS[radar].on('click',function(e){
        const clk = e.target
        var idr = clk.options.ids
        console.log(idr)
       
        if (!map.hasLayer(usCONTRADAR)) { 
            map.removeLayer(mpLCR[NAMES[idr]]);
            map.addLayer(usCONTRADAR);
            console.log("clicked & has layer");
            map.addLayer(MRKSGRP);
        }
        else {
          //vars used in url
          var wms_options = {layers : NAMES[idr].toLowerCase()+radar_mode, format: "image/png", transparent: true}
          customLCRURL = LCRBASEURLA + NAMES[idr].toLowerCase() + LCRBASEURLZ
          mpLCR[NAMES[idr]] =  L.tileLayer.wms(customLCRURL,wms_options).addTo(map);
          map.addLayer(mpLCR[NAMES[idr]])
          console.log(mpLCR[NAMES[idr]])
          map.removeLayer(usCONTRADAR);
          console.log("clicked & does not have layer");
          map.removeLayer(MRKSGRP);
          map.addLayer(mpMRKS[NAMES[idr]]);
        }
        console.log("clicked");
    });
      
      
    }
    
    map.on('zoomend', function (e) {
      const clk = e.target;
      var idr = clk.options.ids
      const ZoomLVL = map.getZoom();
      console.log(map.getZoom());
      console.log(clk.getCenter())
      console.log(map.getBounds());
      // var newSize = 60/((20 - ZoomLVL )*2*10);
      
      // var resizedIcon = new greenIcon({iconSize: [newSize ,newSize ]});  
      // marker.setIcon(resizedIcon );
      if (ZoomLVL < 6){
          if (map.hasLayer(MRKSGRP)) { map.removeLayer(MRKSGRP);console.log("zoomed & does have layer");}
          
        }
      else {
          if ( !map.hasLayer(MRKSGRP)){ map.addLayer(MRKSGRP);console.log("zoomed & does not have layer");}
        }
  });
  
  map.dragging.enable();


  

  


  //sub letters
  const curtmp = L.easyButton( '<div>C</div>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      tempt_mode = "ndfd.conus.t";
      maxtmp.button.setAttribute('style', "opacity: 1.0");
      mintmp.button.setAttribute('style', "opacity: 1.0");
      usCURT.setParams({layers :tempt_mode ,vt: maxTtime });
      usCURTp.setParams({layers :"ndfd.conus.t.points",vt: maxTtime });
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      console.log()
      
    }
    },"current tmp");

  const maxtmp = L.easyButton('<div>M</div>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      tempt_mode ="ndfd.conus.maxt"
      curtmp.button.setAttribute('style', "opacity: 1.0");
      mintmp.button.setAttribute('style', "opacity: 1.0");
      usCURT.setParams({layers :tempt_mode,vt: maxTtime });
      usCURTp.setParams({layers :"ndfd.conus.maxt.points",vt: maxTtime });
      
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      
    }
    },"max tmp");


  const mintmp = L.easyButton( '<div>I</div>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      tempt_mode ="ndfd.conus.mint"
      curtmp.button.setAttribute('style', "opacity: 1.0");
      maxtmp.button.setAttribute('style', "opacity: 1.0");
      usCURT.setParams({layers :tempt_mode,vt: minTtime });
      usCURTp.setParams({layers :"ndfd.conus.mint.points",vt: minTtime});
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      // curtmp.button.setAttribute('style', "opacity: 1.0");
      // maxtmp.button.setAttribute('style', "opacity: 1.0");
    }
    },"min tmp");

  const base = L.easyButton( '<div>B</div>',
    function a(){ 
      if(this.button.getAttribute('style') != "opacity: 0.4") {
        this.button.setAttribute('style', "opacity: 0.4");
        vel.button.setAttribute('style', "opacity: 1.");
        radar_mode = "_sr_bref"
      } 
      else{
        this.button.setAttribute('style', "opacity: 1.0");
        console.log()
      }
      },"reflectivity");


  const vel = L.easyButton( '<div>R</div>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      base.button.setAttribute('style', "opacity: 1.");
      radar_mode = "_sr_bvel"
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      console.log()
    }
    },"velocity");


    //
  //
  const warningButton = L.easyButton( '<img src ="./warning.png"  style="font-size:2em; line-height:26px; display: inline-flex;margin-bottom:25%; vertical-align: middle;width: 100%;"/>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      usCONTWRNINGS.addTo(map)
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      console.log()
      map.removeLayer(usCONTWRNINGS)
    }
    },"warnings").addTo(map);


  const tmpButton = L.easyButton( '<img src ="./thermo.png"  style="font-size:2em; line-height:26px; display: inline-flex;margin-bottom:20%; vertical-align: middle;width: 100%;"/>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      curtmpA = true;
      radarButton.button.setAttribute('style', "opacity: 1.0")
      map.removeLayer(mpLCR);
      map.removeLayer(usCONTRADAR);
      console.log("clicked & does not have layer");
      map.removeLayer(MRKSGRP);
      usCURT.addTo(map) 
      usCURTp.addTo(map) 
      usStates.addTo(map) 
      tmpo.addTo(map);
      if(baserflA){
        map.removeControl(radarb)
      }
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      map.removeControl(tmpo)
      console.log()
      map.removeLayer(usCURT)
      map.removeLayer(usCURTp)
      map.removeLayer(usStates)
    }
    },"temperature").addTo(map);


  const radarButton = L.easyButton( '<img src ="./radar.png"  style=" font-size:2em; line-height:26px; display: inline-flex;margin-bottom:15%; vertical-align: middle;width: 90%;"/>',
  function a(){ 
    if(this.button.getAttribute('style') != "opacity: 0.4") {
      this.button.setAttribute('style', "opacity: 0.4");
      radarb.addTo(map)
      if(curtmpA){
        map.removeControl(tmpo)
      }
      tmpButton.button.setAttribute('style', "opacity: 1.0")
      baserflA = true;
      if(!map.hasLayer(mpLCR)){
        // map.addLayer(mpLCR)
        map.addLayer(usCONTRADAR)
        map.addLayer(MRKSGRP)
      }
      map.removeLayer(usCURT)
      map.removeLayer(usCURTp)
      map.removeLayer(usStates)
    } 
    else{
      this.button.setAttribute('style', "opacity: 1.0");
      console.log()
    }
    },"radar").addTo(map);


    const tmpo = L.easyBar([curtmp,maxtmp,mintmp]);
    const radarb = L.easyBar([base,vel]);
  
// build a toolbar with them

},[])



  return (
   <div id="map" ref = {mapRef} ></div>
  );
}

export default LftMap;