import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Error from "./pages/Error";
import { Analytics } from '@vercel/analytics/react';


const imageURL = "https://hips.hearstapps.com/hmg-prod/images/its-raining-heavily-wearing-an-umbrella-during-the-royalty-free-image-1660153348.jpg"


 


export default function App() {
  const [img, setImg] = useState();
  const fetchImage = async() => {
    const res = await fetch(imageURL);
    const imageBlob = await res.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
  };

  useEffect(()=>{fetchImage();},[]);
  return (
    
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        <Route index element={<Home />} /> 
        <Route path="About" element={<About />} />
        <Route path="Home" element={<Home />} />
        <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));