import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {useEffect, useState} from "react";
import Nav from 'react-bootstrap/Nav';
import logo from './cloud.png';
import './navbar.css'
import React from 'react';

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand
} from 'mdb-react-ui-kit';
 
const imageURL = "https://hips.hearstapps.com/hmg-prod/images/its-raining-heavily-wearing-an-umbrella-during-the-royalty-free-image-1660153348.jpg"

function Navbar_s() {

  const [img, setImg] = useState();
  const fetchImage = async() => {
  const res = await fetch(imageURL);
  const imageBlob = await res.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  setImg(imageObjectURL);
  };

  useEffect(()=>{fetchImage();},[]);

  return (
    <>
       {/* <nav class="navbar  navbar-light bg-light pb-0 pt-0 mb-0 mt-0">
  <div class="container-fluid  pb-0 pt-0 mb-0 mt-0">
      <ul class="navbar-nav me-auto pb-0 pt-0 mb-0 mt-0">
      <img src={logo}
              width="40"
              height="40"
              className="d-inline-block align-top  pt-1 pb-1 pe-1 ps-1"
              alt="React Bootstrap logo"
              ></img>
      </ul>
      <ul class="navbar-nav ms-auto pb-0 pt-0 mb-0 mt-0">
          <a class="nav-link active pb-1 pt-1 mb-0 mt-0" aria-current="page" href="./About">About</a>
      </ul>
  </div>
</nav> */}

   
    </>
  );
}

export default Navbar_s;