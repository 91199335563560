import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById('root'));
alert("Thank you for visiting storm-view (beta), here you can view high resolution radar data as well as temperature data, you can toggle between the options to choose a mode. To view local high resolution data click on the black diamonds. The site is still in development if you have any questions or comments please feel free to reachout via email at storm.view.reach.connect@gmail.com ");
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();